export const sizes = {
    sm: 768,
    md: 1920
}
const size = {
    sm: '768px',
    md: '1440px'
}
export const device = {
    xs: `(max-width: ${size.sm})`,
    sm: `(min-width: ${size.sm}) and (max-width: ${size.md})`,
    md: `(min-width: ${size.md})`,
}