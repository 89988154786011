import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Dash from "./dash";
import BacTable from "./dash/sub-dash/BacTable";
import BacBarChart from "./dash/sub-dash/BacBarChart";
import BacPieChart from "./dash/sub-dash/BacPieChart";
import { sumData } from "utils";
import { bar32, pie33, pie34, pie35} from "utils/colors";
import styled from "styled-components";
import { useDeviceWidth } from "utils/useDeviceDetect";
import DataContext from "context/DataContext";
import { FHANOBYCENTERLABELS, FHPIE2LABELS, H_TITLE } from "utils/constants";
import { device } from "utils/breakpoints";
const StyledContainer = styled(Container)`
  height: 100%;
  @media only screen and ${device.md}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-2, .row-2-1 {
      border-right-width: 0;
    }
    .page-row-1 {
      height: 483px;
    }
    .page-row-2 {
      height: 499px;
    }
  }
  @media only screen and ${device.sm}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-1 {
      border-right-width: 0;
    }
    .row-2-1, .row-2-2 {
      border-bottom-width: 0;
    }
    .row-2-3 {
      border-right-width: 0;
    }
  }
  @media only screen and ${device.xs}{
    .row-1-1, .row-1-2, .row-2-1, .row-2-2 {
      border-bottom-width: 0;
    }
  }
`;
const dataHumainFMHT = [
  {name: "Aucune donnée", value: 100000}
];
const Human = () => {
  const wdth = useDeviceWidth();
  const {
    dataContext: { human },
  } = useContext(DataContext);
  const { fhAnoByCentreAndOuvTypeLastYear, fhAnoByCause, fhAnoByCauseLastYear, fhAnoByOuvTypeLastYear } = human;
  const barLabels = Object.keys(fhAnoByCauseLastYear[0]).map((item) => {
    return {
      name: FHPIE2LABELS[item]
    }
  });
  const newData = Object.keys(fhAnoByCauseLastYear[0]).filter((key) => {
    return key !== 'year'
  }).map((key) => {
    return {
      name: FHPIE2LABELS[key],
      value: fhAnoByCauseLastYear[0][key]
    }
  });
  return (
    <StyledContainer>
      <Row className="page-row-1" >
        <Col xs="12" sm="6" md="6" className="row-1-1">
          <Dash title={H_TITLE[0].title}>
            <BacTable
              data={[...fhAnoByCentreAndOuvTypeLastYear, sumData(fhAnoByCentreAndOuvTypeLastYear)]}
              dataHead={Object.keys(fhAnoByCentreAndOuvTypeLastYear[0]).map(item => FHANOBYCENTERLABELS[item])}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="6" className="row-1-2">
          <Dash title={H_TITLE[1].title}>
            <BacBarChart
              data={fhAnoByCause}
              colors={bar32}
              barLabels={barLabels}
              isFull={true}
            />
          </Dash>
        </Col>
      </Row>
      <Row className="page-row-2" >
        <Col xs="12" sm="6" md="4" className="row-2-1">
          <Dash title={H_TITLE[2].title}>
            <BacPieChart
              data={dataHumainFMHT}
              colors={pie33}
              miniSize={true}
              displayLabel={false}
              displayValue={false}
              isFull={wdth <= 1200}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="4" className="row-2-2">
          <Dash title={H_TITLE[3].title}>
            <BacPieChart
              data={newData}
              colors={pie34}
              miniSize={true}
              displayLabel={false}
              displayValue={false}
              isFull={wdth <= 1200}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="4" className="row-2-3">
          <Dash title={H_TITLE[4].title}>
            <BacPieChart
              data={fhAnoByOuvTypeLastYear}
              colors={pie35}
              miniSize={true}
              displayLabel={false}
              displayValue={false}
              isFull={wdth <= 576 || wdth > 992}
            />
          </Dash>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Human;
