import React, { useContext, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BacTable from './dash/sub-dash/BacTable';
import BacPieChart from './dash/sub-dash/BacPieChart';
import BacLineChart from './dash/sub-dash/BacLineChart';
import Dash from './dash';
import { line13, pie11 } from 'utils/colors';
import styled from "styled-components";
import DataContext from 'context/DataContext';
import { sumData } from 'utils';
import { SYNTHESISTABLABELS, S_TITLE } from 'utils/constants';
import { device } from 'utils/breakpoints';
const StyledContainer = styled(Container)`
  @media only screen and ${device.md}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-2, .row-2-1 {
      border-right-width: 0;
    }
    .page-row-1 {
      height: 483px;
    }
    .page-row-2 {
      height: 499px;
    }
  }
  @media only screen and ${device.sm}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-1, .row-2-3 {
      border-right-width: 0;
    }
    .row-2-1, .row-2-2 {
      border-bottom-width: 0;
    }
  }
  @media only screen and ${device.xs}{
    .row-1-1, .row-1-2, .row-2-1, .row-2-2 {
      border-bottom-width: 0;
    }
  }  
`;
const Synthesis = () => {
  const {
    dataContext,
    setDataContext
  } = useContext(DataContext);
  const { anoEqfY0, aggregatedAnoEqfY0, eqfPerYear, anoPerYear, aggregatedAnoEqfPerYear} = dataContext.synthesis;
  const head = Object.keys(aggregatedAnoEqfY0[0]);
  const ref11 = useRef();
  const ref12 = useRef();
  const ref21 = useRef();
  const ref22 = useRef();
  const ref23 = useRef();
  useEffect(() => {
    setDataContext({
      ...dataContext,
      refs: [...dataContext.refs, {
        title: S_TITLE[0].title,
        ref: ref11
      }, {
        title: S_TITLE[1].title,
        ref: ref12
      }, {
        title: S_TITLE[2].title,
        ref: ref21
      }, {
        title: S_TITLE[3].title,
        ref: ref22
      }, {
        title: S_TITLE[4].title,
        ref: ref23
      }]
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <StyledContainer>
      <Row className='page-row-1'>
        <Col xs="12" sm="6" md="6" className="row-1-1" ref={ref11}>
          <Dash title={S_TITLE[0].title}>
            <BacPieChart data={anoEqfY0} colors={pie11} />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="6" className="row-1-2" ref={ref12}>
          <Dash title={S_TITLE[1].title}>
          <BacTable
              data={[...aggregatedAnoEqfY0, sumData(aggregatedAnoEqfY0)]}
              dataHead={head.map(item => SYNTHESISTABLABELS[item])} />
          </Dash>
        </Col>
      </Row>
      <Row className='page-row-2' ref={ref12}>
        <Col xs="12" sm="6" md="4" className="row-2-1" ref={ref21}>
        <Dash title={S_TITLE[2].title}>
          <BacLineChart
              data={eqfPerYear}
              colors={line13}
              ticks={[0, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000]}
              columnsNumber={2}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="4" className="row-2-2" ref={ref22}>
        <Dash title={S_TITLE[3].title}>
          <BacLineChart data={anoPerYear} colors={line13} ticks={[0, 50, 100, 150, 200, 250, 300]} columnsNumber={2}/>
        </Dash>
        </Col>
        <Col xs="12" sm="6" md="4" className="row-2-3" ref={ref23}>
        <Dash title={S_TITLE[4].title}>
        <BacLineChart data={aggregatedAnoEqfPerYear} colors={line13} ticks={[0, 0.1, 0.15, 0.2, 0.25, 0.30, 0.35]} columnsNumber={2}/>
          </Dash>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Synthesis;
