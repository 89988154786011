import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Dash from "./dash";
import BacTable from "./dash/sub-dash/BacTable";
import BacPieChart from "./dash/sub-dash/BacPieChart";
import BacBarChart from "./dash/sub-dash/BacBarChart";
import { sumData } from "utils";
import { bar42, pie43 } from "utils/colors";
import styled from "styled-components";
import DataContext from "context/DataContext";
import { FMPIE3LABELS, FMPIE4LABELS, FMPIE5LABELS, FMTABLABELS, M_TITLE } from "utils/constants";
import { device } from "utils/breakpoints";
const StyledContainer = styled(Container)`
  height: 100%;
  @media only screen and ${device.md}{
    
    
    .page-row-1 {
      height: 483px;
      .row-1-1 {
        border-right-width: 0;
        border-bottom-width: 0;
      }
      .row-1-2 {
        border-bottom-width: 0;
      }
    }
    .page-row-2 {
      height: 499px;
      .row-2-2, .row-2-1 {
        border-right-width: 0;
      }
    }

  }
  @media only screen and ${device.sm}{
    .page-row-1 {
      .row-1-1 {
        border-right-width: 0;
        border-bottom-width: 0;
      }
      .row-1-2 {
        border-bottom-width: 0;
      }
    }
    .page-row-2 {
      .row-2-1, .row-2-3 {
        border-right-width: 0;
      }
      .row-2-1, .row-2-2 {
        border-bottom-width: 0;
      }
    }
    
  }
  
  @media only screen and ${device.xs}{
    .page-row-1 {
      .row-1-1, .row-1-2 {
        border-bottom-width: 0;
      }
    }
    .page-row-2 {
      .row-2-1, .row-2-2 {
        border-bottom-width: 0;
      }
    }
    
  }
  
`;
const Material = () => {
  const {
    dataContext: { material: {fmAnoByCenterAndTensionLastYear, fmAnoByOuvType, fmAnoByTensionTypeLastYear, fmAnoByOuvLastYear, fmAnoByCauseLastYear} },
  } = useContext(DataContext);
  const newData = Object.keys(fmAnoByCauseLastYear[0]).map((key) => {
    return {
      name: FMPIE5LABELS[key],
      value: fmAnoByCauseLastYear[0][key]
    }
  });
  const newData1 = Object.keys(fmAnoByTensionTypeLastYear[0]).map((key) => {
    return {
      name: FMPIE3LABELS[key],
      value: fmAnoByTensionTypeLastYear[0][key]
    }
  });
  const newData2 = Object.keys(fmAnoByOuvLastYear[0]).map((key) => {
    return {
      name: FMPIE4LABELS[key],
      value: fmAnoByOuvLastYear[0][key]
    }
  });
  const barLabels = Object.keys(fmAnoByOuvLastYear[0]).map((item) => {
    return {
      name: FMPIE4LABELS[item]
    }
  });
  return (
    <StyledContainer>
      <Row className="page-row-1">
        <Col xs="12" sm="6" md="6" className="row-1-1">
          <Dash title={M_TITLE[0].title}>
            <BacTable
              data={[...fmAnoByCenterAndTensionLastYear, sumData(fmAnoByCenterAndTensionLastYear)]}
              dataHead={Object.keys(fmAnoByCenterAndTensionLastYear[0]).map(item => FMTABLABELS[item])}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="6" className="row-1-2">
          <Dash title={M_TITLE[1].title}>
            <BacBarChart
              data={fmAnoByOuvType}
              colors={bar42}
              barLabels={barLabels}
            />
          </Dash>
        </Col>
      </Row>
      <Row className="page-row-2">
        <Col xs="12" sm="6" md="4" className="row-2-1">
          <Dash title={M_TITLE[2].title}>
            <BacPieChart
              data={newData1}
              miniSize={true}
              colors={pie43}
              displayLabel={false}
              displayValue={false}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="4" className="row-2-2">
          <Dash title={M_TITLE[3].title}>
            <BacPieChart
              data={newData2}
              colors={pie43}
              miniSize={true}
              displayLabel={false}
              displayValue={false}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="4" className="row-2-3">
          <Dash title={M_TITLE[4].title}>
            <BacPieChart
              data={newData}
              colors={pie43}
              miniSize={true}
              displayLabel={false}
              displayValue={false}
            />
          </Dash>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Material;
