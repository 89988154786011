import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Dash from "./dash";
import BacPieChart from "./dash/sub-dash/BacPieChart";
import BacBarChart from "./dash/sub-dash/BacBarChart";
import BacTable from "./dash/sub-dash/BacTable";
import { sumData } from "utils";
import { bar52, pie51 } from "utils/colors";
import styled from "styled-components";
import DataContext from "context/DataContext";
import { CONSEQPIEBAR, CONSEQPIEBARLABELS, CONSEQUENCESTABLABELS, C_TITLE } from "utils/constants";
import { device } from "utils/breakpoints";
const StyledContainer = styled(Container)`
  height: 100%;
  @media only screen and ${device.md}{
    .page-row-1 {
      height: 483px;
      .row-1-2 {
        border-bottom-width: 0;
      }
      .row-1-1 {
        border-right-width: 0;
        border-bottom-width: 0;
      }
    }
    .page-row-2 {
      height: 499px;
      .row-2-1 {
        border-right-width: 0;
      }
    }
  }
  @media only screen and ${device.sm}{
    .page-row-1 {
      .row-1-1 {
        border-right-width: 0;
        border-bottom-width: 0;
      }
      .row-1-2 {
        border-bottom-width: 0;
      }
    }
    .page-row-2 {
      .row-2-1 {
        border-right-width: 0;
      }
    }
    
  }
  @media only screen and ${device.xs}{
    .page-row-1 {
      .row-1-1, .row-1-2 {
        border-bottom-width: 0;
      }
    }
    .page-row-2 {
      .row-2-1 {
        border-bottom-width: 0;
      }
    }
  }
  
  
`;
const Consequences = () => {
  const {
    dataContext: { consequences: {anomaliesByConsequenceForLastYear, anomaliesByConsequenceForYear, anomaliesByConsequenceAndCenterForLastYear} },
  } = useContext(DataContext);
  const dataPie = Object.keys(anomaliesByConsequenceForLastYear[0]).map((key) => {
    return {
      name: CONSEQPIEBAR[key],
      value: anomaliesByConsequenceForLastYear[0][key]
    }
  });
  const barLabels = Object.keys(anomaliesByConsequenceForLastYear[0]).map((item) => {
    return {
      name: CONSEQPIEBARLABELS[item]
    }
  });
  return (
    <StyledContainer>
      <Row className="page-row-1">
        <Col xs="12" sm="6" md="6" className="row-1-1">
          <Dash title={C_TITLE[0].title}>
            <BacPieChart
              data={dataPie}
              colors={pie51}
              displayLabel={false}
              displayValue={false}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="6" className="row-1-2">
          <Dash title={C_TITLE[1].title}>
            <BacBarChart
              data={anomaliesByConsequenceForYear}
              colors={bar52}
              barLabels={barLabels}
              isFull={true}
            />
          </Dash>
        </Col>
      </Row>
      <Row className="page-row-2">
        <Col xs="12" sm="6" md="6" className="row-2-1">
          <Dash title={C_TITLE[2].title}>
            <BacTable
              data={[...anomaliesByConsequenceAndCenterForLastYear, sumData(anomaliesByConsequenceAndCenterForLastYear)]}
              dataHead={Object.keys(anomaliesByConsequenceAndCenterForLastYear[0]).map(item => CONSEQUENCESTABLABELS[item])}
            />
          </Dash>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Consequences;
