import DataContext from 'context/DataContext';
import React, { useContext, useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { C_TITLE, H_TITLE, M_TITLE, R_TITLE, S_TITLE } from 'utils/constants';

const StyledRow = styled(Row)`
  row-gap: 16px;
  .modal-title {
      color: var(--bac-grayText);
      font-family: 'Open Sans';
      font-size: 20px;
      line-height: 32px;
  }
  @media only screen and ${device.xs}{
    
  }
  @media only screen and ${device.sm}{
  }
  @media only screen and ${device.md}{
  }
`;

const addOrRemoveExportedItem = (items, item) => {
  if (items.includes(item)) {
    items.splice(items.indexOf(item), 1);
  } else {
    items.push(item);
  }

  return items;
};
const CheckItem = ({title, checks, url}) => {
  const location = useLocation();
  const {
    dataContext,
    setDataContext
  } = useContext(DataContext);
  const { exports } = dataContext;
  useEffect(() => {
    if(location.pathname === `/${url}`) {
      setDataContext({
        ...dataContext,
        exports: [...checks]
      })
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (e) => {
    const tmp = [...S_TITLE, ...R_TITLE, ...H_TITLE, ...M_TITLE, ...C_TITLE].find((item) => {
      return item.title === e.target.name;
    });
    
    setDataContext({
      ...dataContext,
      exports: addOrRemoveExportedItem(exports, tmp)
    });
    
  }
  return (
    <StyledRow className="flex-column">
        <Col className="modal-title" md="12">{title}</Col>
        {
          checks.map((item, index) => {
            return (
            <Col md="12" key={index}>
              <Form.Check type='checkbox'
                label={item.title}
                name={item.title}
                checked={dataContext.exports.includes(item) && item.title !== H_TITLE[2].title}
                onChange={handleChange} disabled={item.title === H_TITLE[2].title}
              />
            </Col>
            )
          })
        }
    </StyledRow>
  )
}

export default CheckItem