import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CartesianGrid, LineChart, XAxis, YAxis, Line, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import Indicators from './Indicators';
import { useDeviceWidth } from 'utils/useDeviceDetect';
import { getXAxisTickLineMargin, getYAxisTickLineMargin, getYAxisLineWidth } from 'utils';
const StyledRow = styled(Row)`
  &.bar-line-row {
    height: 100%;
    align-items: center;
    row-gap: 30px;
    @media (min-width: 577px) and (max-width: 992px) {
      row-gap: 35px;
    }
    .label-row {
      row-gap: 10px;
      .label-item:nth-child(odd) {
        flex-basis: 40%;
      }
      .label-item:nth-child(even) {
        flex-basis: 60%;
      }
      .label-item > .row {
        column-gap: 10px;
        align-items: center;
      }
    }
  }
  
`;


const BacLineChart = ({data, colors, ticks, columnsNumber}) => {
  const objectKeys = Object.keys(data[0]).filter(item => item !== 'year');
  const wdth = useDeviceWidth();
  const dataInd = Object.keys(colors).filter(((item) => {
    return objectKeys.includes(item);
  })).map((item) => {
    return {name: item, values: 0}
  });
  
  const colorsInd = Object.entries(colors).filter(((item) => {
    return objectKeys.includes(item[0]);
  })).map((item) => {
    return item[1];
  });
  
  return (
    <StyledRow className="bar-line-row flex-column">
      <Col>
        <ResponsiveContainer horizontalPoints={ticks} width="100%" height={246} className="responsive-container">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="25" vertical={false}/>
            <XAxis
              dataKey="year"
              tickLine={false}
              tickMargin={getXAxisTickLineMargin(wdth)}
            />
            <YAxis
              type="number"
              axisLine={false}
              ticks={ticks}
              tickLine={false}
              tickMargin={getYAxisTickLineMargin(wdth)}
              width={getYAxisLineWidth(wdth)}
            />
            <Line type="basic" dataKey="Lille" stroke={colors['Lille']} strokeWidth={3} dot={false} animationDuration={250} />
            <Line type="basic" dataKey="Marseille" stroke={colors['Marseille']} strokeWidth={3} dot={false} animationDuration={250} />
            <Line type="basic" dataKey="Nantes" stroke={colors['Nantes']} strokeWidth={3} dot={false} animationDuration={250} />
            <Line type="basic" dataKey="Toulouse" stroke={colors['Toulouse']} strokeWidth={3} dot={false} animationDuration={250} />
            <Line type="basic" dataKey="Lyon" stroke={colors['Lyon']} strokeWidth={3} dot={false} animationDuration={250} />
            <Line type="basic" dataKey="Nancy" stroke={colors['Nancy']} strokeWidth={3} dot={false} animationDuration={250} />
            <Line type="basic" dataKey="Paris" stroke={colors['Paris']} strokeWidth={3} dot={false} animationDuration={250} />
            <Line type="basic" dataKey="Moyenne" stroke={colors['Moyenne']} strokeWidth={3} dot={false} animationDuration={250} />
          </LineChart>
        </ResponsiveContainer>
      </Col>
      <Col>
        <Indicators data={dataInd} columnsNumber={columnsNumber} colors={colorsInd} displayValue={false} />
      </Col>
    </StyledRow>
  );
};

export default BacLineChart;
