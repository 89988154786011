import { C_TITLE, H_TITLE, M_TITLE, R_TITLE, S_TITLE } from 'utils/constants';
import CheckItem from './CheckItem';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';

const StyledRow = styled(Row)`
  row-gap: 16px;
  background-color: white;
  padding: 16px 16px 16px 0;
  height: 676px;
  overflow-x: auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media only screen and ${device.xs}{
    
  }
  @media only screen and ${device.sm}{
  }
  @media only screen and ${device.md}{
  }
`;
const ModalBody = () => {
  return (
    <StyledRow className="flex-column">
      <Col md="12"><CheckItem title='Synthèse' checks={S_TITLE} url='' /></Col>
      <Col md="12"><CheckItem title='Répartition' checks={R_TITLE} url='repartition' /></Col>
      <Col md="12"><CheckItem title='Facteurs humains' checks={H_TITLE} url='ano-fh' /></Col>
      <Col md="12"><CheckItem title='Facteurs matériels' checks={M_TITLE} url='ano-fm' /></Col>
      <Col md="12"><CheckItem title='Conséquences' checks={C_TITLE} url='consequences' /></Col>
    </StyledRow>
  )
}

export default ModalBody