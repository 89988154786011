import React from 'react';
import DataContext from './DataContext';


function GlobalContextProvider({
  children,
}){
  const [dataContext, setDataContext] = React.useState({
    synthesis: {
      aggregatedAnoEqfY0: [],
      anoPerYear: [],
      eqfPerYear: []
    },
    repartition: {
      anoByTypeY0: []
    },
    human: {
      fhAnoByCentreAndOuvTypeLastYear: [],
      fhAnoByCause: [],
      // 3.4
      fhAnoByCauseLastYear: [],
      fhAnoByOuvTypeLastYear: []
    },
    material: {
      // 4.1
      fmAnoByCenterAndTensionLastYear: [],
      fmAnoByOuvType: [],
      fmAnoByTensionTypeLastYear: [],
      fmAnoByCauseLastYear: []
    },
    consequences: {
      // 5.1
      anomaliesByConsequenceForLastYear: [],
      // 5.2
      anomaliesByConsequenceForYear: [],
      // 5.3
      anomaliesByConsequenceAndCenterForLastYear: []
    },
    exports: [],
    refs: []
  });

  return (
    <DataContext.Provider value={{ dataContext, setDataContext }}>
          {children}
    </DataContext.Provider>
  );
}

export default GlobalContextProvider;
  