import React from 'react'
import { Col, Row } from 'react-bootstrap';
import PieIndicator from './PieIndicator';
import styled from 'styled-components';
const StyledRow = styled(Row)`
  &.pie-sub-1 {
    row-gap: 8px;
  }
  &.reverse {
    flex-direction: column-reverse;
  }
  
`;
const Indicators = ({data, colors, displayValue, columnsNumber = 1, reverse}) => {
  return (
    <StyledRow className={`${reverse ? 'flex-wrap pie-sub-1 reverse' : 'flex-wrap pie-sub-1'}`}>
        {data && data.map((item, index) => {
          return (
            <Col xs={12 / columnsNumber} sm={12 / columnsNumber} key={index}>
              <PieIndicator
                key={index}
                item={item}
                color={colors[index]}
                displayValue={displayValue}
                columnsNumber={columnsNumber}
              />
            </Col>
          );
        })}
      </StyledRow>
  )
}

export default Indicators