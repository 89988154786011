//synthese

export const line13 = {
    Lille: "#009CDF", Marseille: "#4DA466", Nantes: "#9D50FF", Toulouse: "#7AE6ED",
    Lyon: "#FBA23A", Nancy: "#F0594A", Paris: "#3F51B5", Moyenne: "#000000",
};
export const pie11 = ["#009CDF", "#7AE6ED"];
//repartition
export const pie21 = ["#7AE6ED", "#009CDF", "#9D50FF"];
export const bar22 = ["#7AE6ED", "#009CDF", "#9D50FF"];
//human
export const bar32 = ["#009cdf", "#fba23a", "#3f51b5", "#b07eff", "#4da466", "#7ae6ed"];
export const pie33 = ["#E8E8E8"];
export const pie34 = ["#009CDF", "#9D50FF", "#3F51B5", "#7AE6ED", "#FF0000", '#008000'];
export const pie35 = ["#009CDF", "#7AE6ED", "#4DA466","#9D50FF", "#3F51B5", "#FBA23A"];
//material
export const bar42 = ["#009cdf", "#fba23a", "#3f51b5", "#b07eff", "#4da466", "#7ae6ed"];
export const pie43 = ["#009CDF", "#E675D4", "#F0594A", "#7AE6ED", "#4DA466", "#9D50FF", "#3F51B5", "#FBA23A"];
//consequences
export const pie51 = ["#E675D4", "#7AE6ED", "#4DA466", "#9D50FF", "#3F51B5", "#FBA23A", "#009CDF", "#FF0000"];
export const bar52 = ["#009CDF", "#7AE6ED", "#3F51B5", "#4DA466", "#F0594A", "#E675D4", "#FBA23A", "#B07EFF"];

