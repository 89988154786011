import React from 'react';

const DataContext = React.createContext({
  dataContext: {
    synthesis: {
      aggregatedAnoEqfY0: [],
      anoPerYear: [],
      eqfPerYear: [],
    },
    repartition: {
      anoByTypeY0: []
    },
    human: {
      fhAnoByCentreAndOuvTypeLastYear: [],
      fhAnoByCause: [],
      fhAnoByCauseLastYear: [],
      fhAnoByOuvTypeLastYear: []
    },
    material: {
      fmAnoByCenterAndTensionLastYear: [],
      fmAnoByOuvType: [],
      fmAnoByTensionTypeLastYear: [],
      fmAnoByCauseLastYear: []
    },
    consequences: {
      anomaliesByConsequenceForLastYear: [],
      anomaliesByConsequenceForYear: [],
      anomaliesByConsequenceAndCenterForLastYear: []
    },
    exports: [],
    refs: []
    //repartition
    
  },
  setDataContext: (data) => {
    // do nothing.
  },
});
DataContext.displayName = 'Data Context';
export default DataContext;
