import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Download from "images/download.svg";
import { Outlet } from 'react-router-dom';
import { device } from 'utils/breakpoints';
import ModalExport from './export';
import DataContext from 'context/DataContext';
const StyledRow = styled(Row)`
  
  &.content-row {
    @media only screen and ${device.md}{
      height: 100%;
      .content-1 {
        border-bottom: 1px solid #CCC8CA;
        flex-basis: 80px;
        padding: 16px;
        img {
          margin-right: 8px;
        }
      }
      .content-2 {
        flex-basis: calc(100% - 80px);
      }
    }
    @media only screen and ${device.sm}{
      .content-1 {
        flex-basis: 50px;
        padding: 8px;
      }
      .content-2 {
        flex-basis: calc(100% - 80px);
      }
    }
    
    @media only screen and ${device.xs}{
      .content-1 {
        flex-basis: 50px;
        padding: 8px;
      }
      .content-2 {
        flex-basis: calc(100% - 80px);
      }
      
    }
    
  }
  
`;
const Content = () => {
  const [show, setShow] = useState(false);
  const {
    dataContext,
    setDataContext
  } = useContext(DataContext);
  const handleClose = () => {
    setDataContext({
      ...dataContext,
      exports: []
    });
    setShow(false);
  };
  const handleShow = () => setShow(true);
  return (
    <StyledRow className="content-row flex-column">
      <Col className="content-1 text-end">
        <Row className="justify-content-end flex-column align-items-center">
          <Col>
            <Button bsPrefix="btn-bac" variant="primary" onClick={handleShow}>
              <img src={Download} alt="download" />Exporter les données
            </Button>
          </Col>
        </Row>
        
      </Col>
      <Col className="content-2">
          <Outlet />
      </Col>
      <ModalExport show={show} handleClose={handleClose} />
    </StyledRow>
  );
};

export default Content;
