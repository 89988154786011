import axios from 'axios';
import { CONSEQPIEBAR, CONSEQUENCESTAB, FHANOBYCENTER, FHPIE2, FMPIE3, FMPIE4, FMPIE5, FMTAB, LAST_YEAR, REPARTITIONPIEBAR, REPARTITIONTAB, SYNTHESISTAB } from 'utils/constants';

const URL = "https://bacchus-backend-dev.rte-ops.com/api";
const PASSWORD = "password=datadevPrvt55468776";
const transformData = (anomaliesByConsequenceForLastYear) => {
  let newAnomaliesByConsequenceForYear2022 = [];
  anomaliesByConsequenceForLastYear.data.forEach(({name, value}, index) => {
    if(name !== null) {
      newAnomaliesByConsequenceForYear2022[0] = {...newAnomaliesByConsequenceForYear2022[0], [name]: value}
    }
  });
  return newAnomaliesByConsequenceForYear2022.map((item) => {
    return {
      fi: item[CONSEQPIEBAR.fi],
      ft: item[CONSEQPIEBAR.ft],
      nf: item[CONSEQPIEBAR.nf],
      no: item[CONSEQPIEBAR.no],
      np: item[CONSEQPIEBAR.np],
      oi: item[CONSEQPIEBAR.oi],
      ot: item[CONSEQPIEBAR.ot],
      sc: item[CONSEQPIEBAR.sc],
    }
  })[0];
}
const transformDataTable = (anomaliesByConsequenceAndCenterForLastYear) => {
  const tmp = anomaliesByConsequenceAndCenterForLastYear.data;
  const newAnomaliesByConsequenceAndCenterForYear2022 = Object.keys(tmp).map((item) => {
    return {
      center: item,
      ...tmp[item]
    }
  });
  return newAnomaliesByConsequenceAndCenterForYear2022;
}
const changeAnomaliesNotation = (anomaliesByTypeForYear2022) => {
  return anomaliesByTypeForYear2022.data.map((item) => {
    return {
      fh: item[REPARTITIONPIEBAR.fh],
      fm: item[REPARTITIONPIEBAR.fm],
      ae: item[REPARTITIONPIEBAR.ae]
    }
  })[0];
}
const transformDataBarFH = (FHAnomaliesByCauseForYear2022) => {
  let newFHAnomaliesByCauseForYear = [];
  FHAnomaliesByCauseForYear2022.data.forEach((element, index) => {
    newFHAnomaliesByCauseForYear[0] = {...newFHAnomaliesByCauseForYear[0], [element.name]: element.value}
  });
  const result = newFHAnomaliesByCauseForYear.map((item) => {
    return {
      ci: item[FHPIE2.ci],
      dp: item[FHPIE2.dp],
      dc: item[FHPIE2.dc],
      mi: item[FHPIE2.mi],
      mic: item[FHPIE2.mic],
      toad: item[FHPIE2.toad],
    }
  });
  return result[0];
}
const transformDataPie5FM = (FMAnomaliesByCauseForYear2022) => {
  
  let newFHAnomaliesByCauseForYear = [];
  FMAnomaliesByCauseForYear2022.data.forEach((element, index) => {
    newFHAnomaliesByCauseForYear[0] = {...newFHAnomaliesByCauseForYear[0], [element.name]: element.value}
  });
  const result = newFHAnomaliesByCauseForYear.map((item) => {
    return {
      cna: item[FMPIE5.cna],
      d: item[FMPIE5.d],
      f: item[FMPIE5.f],
      iai: item[FMPIE5.iai],
      iasc: item[FMPIE5.iasc],
      ina: item[FMPIE5.ina],
      t: item[FMPIE5.t],
    }
  });
  return result[0];
}
const transformDataPie3FM = (FMAnomaliesByCauseForYear2022) => {
  
  let newFHAnomaliesByCauseForYear = [];
  FMAnomaliesByCauseForYear2022.data.forEach((element, index) => {
    newFHAnomaliesByCauseForYear[0] = {...newFHAnomaliesByCauseForYear[0], [element.name]: element.value}
  });
  const result = newFHAnomaliesByCauseForYear.map((item) => {
    return {
      bt: item[FMPIE3.bt],
      ht: item[FMPIE3.ht],
      ns: item[FMPIE3.ns]
    }
  });
  return result[0];
}
const transformDataPie4FM = (FMAnomaliesByOuvTypeForYear2020) => {
  let newFHAnomaliesByCauseForYear = [];
  FMAnomaliesByOuvTypeForYear2020.data.forEach((element, index) => {
    newFHAnomaliesByCauseForYear[0] = {...newFHAnomaliesByCauseForYear[0], [element.name]: element.value}
  });
  const result = newFHAnomaliesByCauseForYear.map((item) => {
    return {
      bc: item[FMPIE4.bc],
      lt: item[FMPIE4.lt],
      lid: item[FMPIE4.lid],
      b: item[FMPIE4.b],
      tb: item[FMPIE4.tb],
    }
  });
  return result[0];
}
export const getData = async () => {
  // synthesis
  const anoEqfY0 = await axios.get(
    `${URL}/anoEqfPerYear/${LAST_YEAR}?${PASSWORD}`
  );
  const aggregatedAnoEqfY0 = await axios.get(
    `${URL}/aggregatedAnoEqfPerYear/${LAST_YEAR}?${PASSWORD}`
  );
  const anoPerYear = await axios.get(
    `${URL}/anoPerYear?${PASSWORD}`
  );
  const eqfPerYear = await axios.get(
    `${URL}/eqfPerYear?${PASSWORD}`
  );
  const aggregatedAnoEqfPerYear = await axios.get(
    `${URL}/aggregatedAnoEqf?${PASSWORD}`
  );
  // repartition
  const anoByTypeY0 = await axios.get(
    `${URL}/anomaliesByTypeForYear/${LAST_YEAR}?${PASSWORD}`
  );//Pie
  const anoByTypeY1 = await axios.get(
    `${URL}/anomaliesByTypeForYear/${LAST_YEAR - 1}?${PASSWORD}`
  );
  const anoByTypeY2 = await axios.get(
    `${URL}/anomaliesByTypeForYear/${LAST_YEAR - 2}?${PASSWORD}`
  );
  const anoByTypeY3 = await axios.get(
    `${URL}/anomaliesByTypeForYear/${LAST_YEAR - 3}?${PASSWORD}`
  );
  const anoByTypeY4 = await axios.get(
    `${URL}/anomaliesByTypeForYear/${LAST_YEAR - 4}?${PASSWORD}`
  );
  const anoByTypeY5 = await axios.get(
    `${URL}/anomaliesByTypeForYear/${LAST_YEAR - 5}?${PASSWORD}`
  );//bar
  const anoByTypeAndLocationY0 = await axios.get(
    `${URL}/anomaliesByTypeAndLocation/${LAST_YEAR}?${PASSWORD}`
  );//Table
  
  // human
  const fhAnoByCentreAndOuvTypeLastYear = await axios.get(
    `${URL}/FHAnomaliesByCentreAndOuvrageType/${LAST_YEAR}?${PASSWORD}`
  );//Table
  //fhAnoByCause
  const fhAnoByCauseLastYear = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/${LAST_YEAR}?${PASSWORD}`
  );
  const fhAnoByCauseYear2022 = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/${LAST_YEAR - 1}?${PASSWORD}`
  );
  const fhAnoByCauseYear2021 = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/${LAST_YEAR - 2}?${PASSWORD}`
  );
  const fhAnoByCauseYear2020 = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/${LAST_YEAR - 3}?${PASSWORD}`
  );
  const fhAnoByCauseYear2019 = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/${LAST_YEAR - 4}?${PASSWORD}`
  );
  const fhAnoByCauseYear2018 = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/${LAST_YEAR - 5}?${PASSWORD}`
  );
  const fhAnoByOuvTypeLastYear = await axios.get(
    `${URL}/FHAnomaliesByOuvrageType/${LAST_YEAR}?${PASSWORD}`
  );
  // material
  const fmAnoByCenterAndTensionLastYear = await axios.get(
    `${URL}/FMAnomaliesByDomainForYear/${LAST_YEAR}?${PASSWORD}`
  ); //Table
  //START Bar
  const fmAnoByOuvTypeForLastYear = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/${LAST_YEAR}?${PASSWORD}`
  );
  const fmAnoByOuvTypeForYear2022 = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/${LAST_YEAR - 1}?${PASSWORD}`
  );
  const fmAnoByOuvTypeForYear2021 = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/${LAST_YEAR - 2}?${PASSWORD}`
  );
  const fmAnoByOuvTypeForYear2020 = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/${LAST_YEAR - 3}?${PASSWORD}`
  );
  const fmAnoByOuvTypeForYear2019 = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/${LAST_YEAR - 4}?${PASSWORD}`
  );
  const fmAnoByOuvTypeForYear2018 = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/${LAST_YEAR - 5}?${PASSWORD}`
  ); // END Bar
  const fmAnoByTensionTypeLastYear = await axios.get(
    `${URL}/FMAnomaliesByTensionTypeForYear/${LAST_YEAR}?${PASSWORD}`
  );//4

  const fmAnoByCauseLastYear = await axios.get(
    `${URL}/FMAnomaliesByCauseForYear/${LAST_YEAR}?${PASSWORD}`
  );//5
  //4
  
  // consequences
  //Pie
  const anomaliesByConsequenceForLastYear = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/${LAST_YEAR}?${PASSWORD}`
  );
  //START Bar
  const anomaliesByConsequenceForYear2022 = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/${LAST_YEAR - 1}?${PASSWORD}`
  );
  const anomaliesByConsequenceForYear2021 = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/${LAST_YEAR - 2}?${PASSWORD}`
  );
  const anomaliesByConsequenceForYear2020 = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/${LAST_YEAR - 3}?${PASSWORD}`
  );
  const anomaliesByConsequenceForYear2019 = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/${LAST_YEAR - 4}?${PASSWORD}`
  );
  const anomaliesByConsequenceForYear2018 = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/${LAST_YEAR - 5}?${PASSWORD}`
  );
  //END Bar
  const anomaliesByConsequenceAndCenterForLastYear = await axios.get(
    `${URL}/anomaliesByConsequenceAndCenterForYear/${LAST_YEAR}?${PASSWORD}`
  ); //TABLE
  return {
    synthesis: {
      anoEqfY0: Object.keys(anoEqfY0.data[0]).map((item) => {
        return {
          name: item,
          value: anoEqfY0.data[0][item]
        }
      }),
      aggregatedAnoEqfY0: aggregatedAnoEqfY0.data.map((item) => {
        return {
          center: item[SYNTHESISTAB.center],
          nb_ano: item[SYNTHESISTAB.nb_ano],
          nb_eqf: item[SYNTHESISTAB.nb_eqf],
          ratio: item[SYNTHESISTAB.ratio],
        }
      }),
      anoPerYear: anoPerYear.data,
      eqfPerYear: eqfPerYear.data,
      aggregatedAnoEqfPerYear: aggregatedAnoEqfPerYear.data
    },
    repartition: {
      anoByTypeY0: [changeAnomaliesNotation(anoByTypeY0)], // graphes
      anoByType: [
        {
          year: LAST_YEAR,
          ...changeAnomaliesNotation(anoByTypeY0)
        },
        {
          year: LAST_YEAR - 1,
          ...changeAnomaliesNotation(anoByTypeY1)
        },
        {
          year: LAST_YEAR - 2,
          ...changeAnomaliesNotation(anoByTypeY2),
        },
        {
          year: LAST_YEAR - 3,
          ...changeAnomaliesNotation(anoByTypeY3)
        },
        {
          year: LAST_YEAR - 4,
          ...changeAnomaliesNotation(anoByTypeY4)
        },
        {
          year: LAST_YEAR - 5,
          ...changeAnomaliesNotation(anoByTypeY5)
        }
      ],
      anoByTypeAndLocationY0: anoByTypeAndLocationY0.data.map((item) => { // Table
        const fh = item[REPARTITIONTAB.fh] || 0;
        const fm = item[REPARTITIONTAB.fm] || 0;
        const ae = item[REPARTITIONTAB.ae] || 0;
        return {
          center: item[REPARTITIONTAB.center],
          fh,
          fm,
          ae,
          ta: fh + fm + ae
        }

      })
    },
    human: {
      fhAnoByCentreAndOuvTypeLastYear: fhAnoByCentreAndOuvTypeLastYear.data.map((item) => {
        return {
          center: item[FHANOBYCENTER.center],
          cutOffBlock: item[FHANOBYCENTER.cutOffBlock],
          transitLine: item[FHANOBYCENTER.transitLine],
          InterBreakerLine: item[FHANOBYCENTER.InterBreakerLine],
          station: item[FHANOBYCENTER.station],
          btCell: item[FHANOBYCENTER.btCell],
          fhTotal: item[FHANOBYCENTER.fhTotal]
        }
      }), // Table
      // 3.3 see the file directly
      fhAnoByCause: [
        {year: LAST_YEAR, ...transformDataBarFH(fhAnoByCauseLastYear)},
        {year: LAST_YEAR - 1, ...transformDataBarFH(fhAnoByCauseYear2022)},
        {year: LAST_YEAR - 2, ...transformDataBarFH(fhAnoByCauseYear2021)},
        {year: LAST_YEAR - 3, ...transformDataBarFH(fhAnoByCauseYear2020)},
        {year: LAST_YEAR - 4, ...transformDataBarFH(fhAnoByCauseYear2019)},
        {year: LAST_YEAR - 5, ...transformDataBarFH(fhAnoByCauseYear2018)}
      ],
      fhAnoByCauseLastYear: [transformDataBarFH(fhAnoByCauseLastYear)], // 4
      fhAnoByOuvTypeLastYear: fhAnoByOuvTypeLastYear.data,
    },
    material: {
      fmAnoByCenterAndTensionLastYear: fmAnoByCenterAndTensionLastYear.data.map((item) => {
        return {
          center: item[FMTAB.center],
          bt: item[FMTAB.bt] || 0,
          ht: item[FMTAB.ht] || 0,
          ns: item[FMTAB.ns] || 0,
        }
      }), // Table
      fmAnoByOuvType: [
        {year: LAST_YEAR, ...transformDataPie4FM(fmAnoByOuvTypeForLastYear)},
        {year: LAST_YEAR - 1, ...transformDataPie4FM(fmAnoByOuvTypeForYear2022)},
        {year: LAST_YEAR - 2, ...transformDataPie4FM(fmAnoByOuvTypeForYear2021)},
        {year: LAST_YEAR - 3, ...transformDataPie4FM(fmAnoByOuvTypeForYear2020)},
        {year: LAST_YEAR - 4, ...transformDataPie4FM(fmAnoByOuvTypeForYear2019)},
        {year: LAST_YEAR - 5, ...transformDataPie4FM(fmAnoByOuvTypeForYear2018)},
      ],
      fmAnoByTensionTypeLastYear: [transformDataPie3FM(fmAnoByTensionTypeLastYear)], //4.3
      fmAnoByOuvLastYear: [transformDataPie4FM(fmAnoByOuvTypeForLastYear)], //4.4
      fmAnoByCauseLastYear: [transformDataPie5FM(fmAnoByCauseLastYear)] //4.5
    },
    consequences: {
      anomaliesByConsequenceForLastYear: [transformData(anomaliesByConsequenceForLastYear)],
      anomaliesByConsequenceForYear: [
        {year: LAST_YEAR, ...transformData(anomaliesByConsequenceForLastYear)},
        {year: LAST_YEAR - 1, ...transformData(anomaliesByConsequenceForYear2022)},
        {year: LAST_YEAR - 2, ...transformData(anomaliesByConsequenceForYear2021)},
        {year: LAST_YEAR - 3, ...transformData(anomaliesByConsequenceForYear2020)},
        {year: LAST_YEAR - 4, ...transformData(anomaliesByConsequenceForYear2019)},
        {year: LAST_YEAR - 5, ...transformData(anomaliesByConsequenceForYear2018)},
      ],
      anomaliesByConsequenceAndCenterForLastYear: transformDataTable(anomaliesByConsequenceAndCenterForLastYear).map((item) => {
        return {
          center: item[CONSEQUENCESTAB.center] || 0,
          fi: item[CONSEQUENCESTAB.fi] || 0,
          ft: item[CONSEQUENCESTAB.ft] || 0,
          nf: item[CONSEQUENCESTAB.nf] || 0,
          no: item[CONSEQUENCESTAB.no] || 0,
          oi: item[CONSEQUENCESTAB.oi] || 0,
          ot: item[CONSEQUENCESTAB.ot] || 0,
          sq: item[CONSEQUENCESTAB.sq] || 0,
          np: item[CONSEQUENCESTAB.np] || 0,
        }
      })
    }
    
  }
};

export const exportData = async (exports) => {
  axios({
    url: `${URL}/aggregateExcels?${PASSWORD}`,
    method: 'POST',
    responseType: 'blob',
    data: exports
  }).then((response) => {
    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    var fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'file.zip');
    document.body.appendChild(fileLink);
    fileLink.click();
  });
}