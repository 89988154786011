import React, { useContext, useRef } from 'react'
import { Button, Modal } from 'react-bootstrap';
import ModalBody from './ModalBody';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PDFDocument from './PDFDocument';
import html2canvas from 'html2canvas';
import DataContext from 'context/DataContext';
import { S_TITLE } from 'utils/constants';
import { useDeviceWidth } from 'utils/useDeviceDetect';
import { exportData } from 'services/data';

const exportAsImage = async (el, imageFileName) => {
  const canvas = await html2canvas(el);
  const image = canvas.toDataURL("image/png", 1.0);
  downloadImage(image, imageFileName);
};
const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;
  
  fakeLink.href = blob;
  
  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);
  
  fakeLink.remove();
};
const StyledModal = styled(Modal)`
  row-gap: 16px;
  .modal-header {
    padding-bottom: 8px;
    .btn-close {
      border: 3px solid black;
      opacity: 1;
      border-radius: 5px;
      font-size: 15px;
      font-weight: bold;
      padding: 6px;
      position: relative;
      right: 6px;
    }
    .modal-title {
      font-family: 'Open Sans';
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
    }
  }
  .modal-header, .modal-body, .modal-footer {
    border-radius: 0;
    border: 0;
  }
  .modal-content {
    padding: 16px;
  }
  .modal-footer {
    padding-bottom: 24px;
  }
  @media only screen and ${device.xs}{
    
  }
  @media only screen and ${device.sm}{
  }
  @media only screen and ${device.md}{
  }
`;

const ModalExport = ({show, handleClose}) => {
  const {
    dataContext: {refs, exports}
  } = useContext(DataContext);
  const tmp = refs.find((item) => {
    return item.title === S_TITLE[0]
  });
  const wdth = useDeviceWidth();
  // eslint-disable-next-line no-unused-vars
  const handleExport = () => {
    exportData(exports.map(({endpoint, params}) => {
      return { endpoint, params }
    }));
    // exportAsImage(tmp.ref.current, "test");
    handleClose();
  }
  return (
    <StyledModal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title>Exporter les données</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ModalBody />
        </Modal.Body>
        <Modal.Footer>
          <Button bsPrefix="btn-bac" variant="thirdy" onClick={handleClose}>
            Annuler
          </Button>
          <Button bsPrefix="btn-bac" variant="secondary" onClick={handleExport} disabled={exports.length === 0}>
            Exporter
          </Button>
          {/*<PDFViewer>
            <PDFDocument />
          </PDFViewer>
          <div>
    <PDFDownloadLink document={<PDFDocument />} fileName="somename.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : 'Download now!'
      }
    </PDFDownloadLink></div>*/}
  
        </Modal.Footer>
    </StyledModal>
  )
}

export default ModalExport;