import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Dash from './dash';
import BacPieChart from './dash/sub-dash/BacPieChart';
import BacTable from './dash/sub-dash/BacTable';
import BacBarChart from './dash/sub-dash/BacBarChart';
import { sumData } from 'utils';
import { bar22, pie21 } from 'utils/colors';
import styled from "styled-components";
import DataContext from 'context/DataContext';
import { REPARTITIONBARLABELS, REPARTITIONPIELABELS, REPARTITIONTABLABELS, R_TITLE } from 'utils/constants';
import { device, sizes } from 'utils/breakpoints';
import { useDeviceWidth } from 'utils/useDeviceDetect';
const StyledContainer = styled(Container)`
  height: 100%;
  @media only screen and ${device.md}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-1 {
      border-right-width: 0;
    }
    .page-row-1 {
      height: 483px;
    }
    .page-row-2 {
      height: 499px;
    }
  }
  @media only screen and ${device.sm}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-1 {
      border-right-width: 0;
    }
  }
  @media only screen and ${device.xs}{
    .row-1-1, .row-1-2 {
      border-bottom-width: 0;
    }
  }
`;
const getColumnsNumber = (wdth) => {
  if(wdth <= sizes.sm) {
    return 2;
  }
  return 1;
}
const Repartition = () => {
  const wdth = useDeviceWidth();
  const {
    dataContext: { repartition: {anoByTypeY0, anoByType,  anoByTypeAndLocationY0} },
  } = useContext(DataContext);
  const newData = Object.keys(anoByTypeY0[0]).filter((key) => {
    return key !== 'year'
  }).map((key) => {
    return {
      name: REPARTITIONPIELABELS[key],
      value: anoByTypeY0[0][key]
    }
  });
  const barLabels = Object.keys(anoByType[0]).filter((item) => item !== 'year').map((item) => {
    return {
      name: REPARTITIONBARLABELS[item]
    }
  });
  return (
    <StyledContainer>
      <Row className='page-row-1'>
        <Col xs="12" sm="6" md="6" className="row-1-1">
          <Dash title={R_TITLE[0].title}>
            <BacPieChart
              data={newData}
              colors={pie21}
              displayValue={false}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="6" className="row-1-2">
          <Dash title={R_TITLE[1].title}>
            <BacBarChart
              data={anoByType}
              colors={bar22}
              barLabels={barLabels}
              columnsNumber={getColumnsNumber(wdth)}
            />
          </Dash>
        </Col>
      </Row>
      <Row className='page-row-2'>
        <Col md="6" sm="6" className="row-2-1">
          <Dash title={R_TITLE[2].title}>
            <BacTable
              data={[...anoByTypeAndLocationY0, sumData(anoByTypeAndLocationY0)]}
              dataHead={Object.keys(anoByTypeAndLocationY0[0]).map(item => REPARTITIONTABLABELS[item])}
            />
          </Dash>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Repartition;
