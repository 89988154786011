import React from "react";
import { Col, Row } from "react-bootstrap";
import { Pie, PieChart, Cell, ResponsiveContainer, Tooltip } from "recharts";
import styled from "styled-components";
import Indicators from "./Indicators";
import {useDeviceWidth} from "utils/useDeviceDetect";
import { device, sizes } from "utils/breakpoints";
import { getPieHeight } from "utils";

const renderCustomizedLabel = (props, colors) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } = props;
  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill={colors[index]}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      className="percent-pie"
      style={{lineHeight: "28px", fontSize: "18px", border: "1px solid red"}}
    >
      {Math.round(percent.toFixed(2) * 100)}%
    </text>
  );
};
const StyledRow = styled(Row)`
  &.pie-chart-row {
    flex-wrap: wrap;
    column-gap: 20px;
    height: 100%;
    align-items: center;
    row-gap: 32px;
    align-content: flex-start;
    @media (min-width: 576px) and (max-width: 992px) {
      align-content: flex-start;
      
    }
    .pie-chart-item-2 {
      align-self: center;
      flex: 1;
    }
  }
  @media only screen and ${device.md}{
    &.pie-chart-row {
      .pie-chart-item-2 {
        height: 185px;
      }
    }
    
  }
  @media only screen and ${device.sm}{
    row-gap: 30px;
    .pie-chart-item-1, .pie-chart-item-2 {
      width: 100%;
    }
  }
  @media only screen and ${device.xs}{
    &.pie-chart-row {
      row-gap: 30px;
    }
  }
  
  

  path.recharts-pie-label-line {
    stroke-width: 1px !important;
  }
`;
const getPieOuterRadius = (wdth) => {
  if(wdth >= sizes.md) {
    return 102;
  } else {
    return 80;
  }
}
const getPieInnerRadius = (wdth) => {
  if(wdth <= sizes.md) {
    return 50;
  } else {
    return 60;
  }
}
const BacPieChart = ({
  data,
  colors,
  miniSize,
  displayLabel = true,
  displayValue = true,
  columnsNumber
}) => {
  const wdth = useDeviceWidth();
  return (
    <StyledRow className="pie-chart-row">
      <Col sm="12" md={miniSize ? 12 : 6} className="pie-chart-item-1">
        <ResponsiveContainer
          width="100%"
          height={getPieHeight(wdth)}
          className="responsive-container"
        >
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={displayLabel}
              label={(props) =>
                displayLabel ? renderCustomizedLabel(props, colors) : null
              }
              outerRadius={getPieOuterRadius(wdth)}
              innerRadius={getPieInnerRadius(wdth)}
              startAngle={0}
              endAngle={-380}
              paddingAngle={0}
              fill="#8884d8"
              dataKey="value"
              animationBegin={0}
              animationDuration={250}
            >
              {data.map((entry, index) => {
                return (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index]}
                  strokeWidth={4}
                />
              )})}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Col>
      <Col sm="12" md="6" className="pie-chart-item-2">
        <Indicators data={data} columnsNumber={columnsNumber} colors={colors}  displayValue={displayValue}/>
      </Col>
    </StyledRow>
  );
};

export default BacPieChart;
